import React, { createContext, memo, useCallback, useContext, useEffect, useState } from "react";
import Style from "../styles/calc.module.css";
import {  Button, Select } from "antd";
import {  CloseOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import { CalculatorChildType, resultType, scenarioType, submitScenarioType, tabType } from "../@types/user";
import DoublePopup from "./inputs/DoublePopup";
import Text from "./inputs/Text";
import Number from "./inputs/Number";
import BoolDrop from "./inputs/BoolDrop";
import Decisive from "./inputs/Decisive";
// import OutputScreen from "./output/OutputScreen";
import Popup from "./inputs/Popup";
import ModalBox from "./modal";
import TimelineContainer from "./timeline";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { getData, resetFormEntries, setCurrency, setIds, verifyAllJobs, verifyFormPage } from "../redux/calculatorSlice";
import Clock from "./Clock";
import NavigationIcon from '@mui/icons-material/Navigation';
import SettingsIcon from '@mui/icons-material/Settings';
import { Axios } from "../Axios";
import { VERSION } from "../config";
import InputBox from "./input";
import { Checkbox, Modal } from "@mui/material";
import OutputScreen from "./output/OutputScreen";
import Flynaut from "./branding/Flynaut";
import OneScreenPopup from "./inputs/OneScreenPopup";

type calcProp = {
    className:string

}
const Calculator = ({className}:calcProp)=>{

    const user = useSelector((state: RootState) => state.user.user );
    const [loading,setLoading] = useState<boolean>(false);

    const version = useSelector((state:RootState)=> (state.calculator.tabs[state.calculator.activeTab].version ));
    
    const activeTab = useSelector((state: RootState) => state.calculator.activeTab);
    const activeScenarioIndex = useSelector((state: RootState) => state.calculator.activeScenario);
    const activeJob = useSelector((state: RootState) => state.calculator.activeJob);
    const activeTabJobs = useSelector((state: RootState) => state.calculator.activeScenarioJobs);   
    const splitData = useSelector((state: RootState) => state.calculator.activeScreen?.jobs[state.calculator.activeJob].splitData);
    const myCurrency = useSelector((state: RootState) => state.calculator.activeScreen?.currency || "dollar");
    const calcList = useSelector((state: RootState) => state.calculator.activeScreen?.jobs[state.calculator.activeJob].data || []);
    const activeIndex = useSelector((state: RootState) => (state.calculator.activeScreen?.jobs[state.calculator.activeJob].activeIndex || 0));
    const [submitableScenario,setSubmitableScenario] = useState<submitScenarioType[]>([]);


    const dispatch = useDispatch();

    const [error,setError] = useState(false);
    const [localData,setLocalData] = useState<CalculatorChildType[][]>([]);
    const [confirmRestart,setConfirmRestart] = useState(false);

    const [settingOpen,setSettingOpen] = useState(false);
    const allScenarios = useSelector((state: RootState) => state.calculator.activeTabScenarios );
    const [selectedScenariosIndex,setSelectedScenariosIndex] = useState<{ind:number,jobs:number[]}[]>([]);
    const [changableScenariosIndex,setChangableScenariosIndex] = useState<{ind:number,jobs:number[]}[]>([{
        ind:activeScenarioIndex,
        jobs:[activeJob]
    }]);

    const [customError,setCustomError] = useState("");
    const [result,setResult] = useState<resultType>([]);

    useEffect(()=>{

        let newx:CalculatorChildType[][] = [];

        calcList.forEach(element => {
            newx.push( element.filter((felem,index)=> ( index === activeIndex) )[0].child );
        });

        setLocalData(newx);
        setResult([]);

        console.log("version", version);

    },[activeIndex,calcList]);

    useEffect(()=>{

        let dec = activeTabJobs[activeJob].type == 1 ? 
        activeTabJobs[activeJob].splitData?.map(elem=> parseInt(elem))  : 
        [activeJob];

        setChangableScenariosIndex([{
            ind:activeScenarioIndex,
            jobs:dec!
        }]);

    },[activeScenarioIndex,activeJob]);

    useEffect(()=>{
        if(settingOpen){
            let err = dispatch( verifyFormPage(calcList[0].length) );
        }

    },[settingOpen])


    const restartForm = useCallback(()=>{

        dispatch( resetFormEntries() );
    },[])

    const submitData = ()=>{
        
        setLoading(true);
        
        let pre = dispatch(getData(""));
        let data = JSON.parse(pre.payload);
        let calc:tabType = data.tabs;

        console.log("payload",data);

        type Sender={
            tabId:string,
            tabName:string,
            scenarios:submitScenarioType[],
            version:string
        }
        
        let allSelectedScenarios:scenarioType[] = [];
        let choose = selectedScenariosIndex.length > 0 ? selectedScenariosIndex : changableScenariosIndex;
        choose.forEach((element) => {

            allSelectedScenarios.push( calc[data.activeTab].scenario[element.ind]  );
            
        });
    
        let allScenarios:submitScenarioType[] = allSelectedScenarios.map((selem,eindex)=> (
           
                {
                    id: selem.id || "",
                    key: selem.key,
                    name:selem.name,
                    currency:selem.currency,
                    type:selem.type,
                    jobs:selem.jobs.filter((elem,i)=> choose[eindex].jobs.indexOf(i) !== -1 ).map((jelem,jindex)=>(
                         {
                            id: jelem.id || "",
                            key: jelem.key,
                            name:jelem.name,
                            data:JSON.stringify( jelem.data[0] )
                        }
                    ))
                }
            
        ))

        setSubmitableScenario(allScenarios);

        let sendOb:Sender = {
            tabId : calc[data.activeTab].id || "",
            tabName : calc[data.activeTab].tabName,
            scenarios : allScenarios,
            version : data.tabs[data.activeTab].version || "v2"
        }
    
        
        Axios.post(`/api/v1/calculator`,{
            ...sendOb
        },{
            headers:{
                Authorization: user?.token
            }
        })
        .then((result:any)=>{

            setLoading(false);
            let ob = result.data.data;

            // setting Id to active tab and all its scenarios and jobs.
            dispatch( setIds({ids:selectedScenariosIndex.map(elem=> elem.ind),received:ob.received,tabId:ob.tabId}) )
            let x:resultType = ob.output;

            // re arranging quoted and current scenarios at starting of array.
            let prep:any = [];
            let quoted=null;
            let current=null;

            x.forEach(element => {
                if(element.name.toLowerCase() === "quoted"){
                    quoted = element;
                }
                else if(element.name.toLowerCase() === "current"){
                    current = element;
                }
                else{
                    prep = [...prep,element];
                }
            });
            if(current !== null){
                prep.unshift(current);
            }
            if(quoted !== null){
                prep.unshift(quoted);
            }

            
            setResult(prep);
        })
        .catch(err=>{
            setLoading(false);
            console.log("err", err);
        })
    }

    const verifyForm = useCallback(()=>{

        dispatch( verifyFormPage(activeIndex + 1) ); 
      
    },[activeIndex]);

    const calculate = ()=>{

        let err = dispatch( verifyFormPage(calcList[0].length) );
        let res:any = dispatch( verifyAllJobs(selectedScenariosIndex.length > 1 ? selectedScenariosIndex : changableScenariosIndex) ).payload;
        
        if(!res.status){

            setError(true);
        }
        else{
            submitData();
        }
    }

    const selectedScenarioChangeHandler = (checked:boolean,ind:number)=>{ 

        let locSelectedScenario = [...selectedScenariosIndex];
        if(checked){
            let x ={
                ind : ind,
                jobs:[]
            }
            let res = verifySelectedScenarios([x]);
          
            if(!res.status){
    
                return setCustomError(`
                Scenario '${res.scName}' Job '${res.jbName}' entries are incomplete. 
                Please complete all the required form entries before proceeding !
                `);
            }
            locSelectedScenario.push(x);
        }
        else{

            locSelectedScenario = locSelectedScenario.filter(el=> el.ind !== ind );
        }
 
        setSelectedScenariosIndex(locSelectedScenario);
        
    }

    const jobChangeHandler = (index:number,e:string[])=>{

        let parsed = e.map(elem=> parseInt(elem));

        let x = selectedScenariosIndex.map(el=> ({...el,jobs:[...el.jobs]}) );

        x.forEach(element => {

            if(element.ind === index){
                element.jobs = parsed;
            }
            
        });

        let res = verifySelectedScenarios(x);

        if(!res.status){
    
            return setCustomError(`
            (${res.jbName}) 
            Please complete all the required form entries before proceeding !
            `);
        }
        else{
            setSelectedScenariosIndex(x);
        }
        

        

    }

    const selectedScenarioSaveHandler = ()=>{

        if(selectedScenariosIndex.length < 1){
            setCustomError(`
            All of the Scenarios can't be unselected!`);


            return;
        }
        
        let proceed = true;
        selectedScenariosIndex.forEach((element) => {
            if(element.jobs.length < 1){
                proceed = false;
            }
        });

        if(!proceed){

            setCustomError(`
            All of the Jobs can't be unselected !
            `);

            return;
        }
        else{

            let res = verifySelectedScenarios( selectedScenariosIndex);
            if(!res.status){

                setCustomError(`
                One or more scenario entries are incomplete. 
                Please complete all the required form entries before proceeding !
                `);
                return;
            }
            else{
                setSettingOpen(false);
            }

        }
    
  
    }

    const verifySelectedScenarios = (e:{ind:number,jobs:number[]}[])=>{

        const sce:scenarioType[] = e.map((elem,index)=> ({...allScenarios[elem.ind],jobs: allScenarios[elem.ind].jobs.filter((el,ind)=> elem.jobs.indexOf(ind) !== -1 )  }));

        let proceed = true;
        let scName = "";
        let jbName = "";

        sce.forEach(element => {
            
            element.jobs.forEach(elem => {

                elem.data[0].forEach(ielem => {

                    if(!ielem.cmp){
                        proceed = false;
                        scName = element.name;
                        jbName = elem.name;
                    }
                    
                });
                
            });

        });

        if(!proceed){

            return {scName,jbName,status:false};
        }
        else{
            return {scName,jbName,status:true};
        }
       
    }

    const settingCloseHandler = ()=>{
        setSelectedScenariosIndex([]);
        setSettingOpen(false);

    }


    return (
        <div className={className}>
            {
                error &&
                <ModalBox 
                open={error}
                header="Incomplete Details" 
                message="You have incomplete fields in one or more Jobs which are crucial for calculation !"
                onOk={()=>{
                    setError(false)
                }}
                onClose={()=>{
                    setError(false);
                }}
                />
            }
            {
                confirmRestart &&
                <ModalBox 
                open={confirmRestart}
                header="Confirm !" 
                message="Are you sure you want to clear all form entries !"
                onOk={()=>{
                    restartForm();
                    setConfirmRestart(false);
                }}
                onClose={()=>{
                    setConfirmRestart(false);
                }}
                onCancel={()=>{
                    setConfirmRestart(false);
                }}
                />
            }
             {
                customError &&
                <ModalBox 
                open={customError ? true : false}
                header="Error !" 
                message={customError}
                onOk={()=>{
                    setCustomError("");
                }}
                onClose={()=>{
                    setCustomError("");
                }}
                />
            }
            {
                settingOpen &&
                <Modal
                sx={{
                    zIndex:100
                }}
                className="modal"
                open={true}
                onClose={settingCloseHandler}
               >
       
               <div className="modalPopUp">
       
                       <CloseOutlined
                       className="popupClose" 
                       onClick={settingCloseHandler}
                       />
                      
                           <div className={Style.setCnt}>
                            <p className={Style.setTitle}>Include Scenarios and their Jobs in calculation</p>

                            {
                                allScenarios.map((elem,index)=>(
                                <div className={Style.setScenarioCnt} key={`setSceInd${index}`}>
                                    <div className={Style.setScenarioLeft}>
                                        <Checkbox 
                                        className={Style.setChkBox}
                                        id={`setLabel${index}`}
                                        checked={selectedScenariosIndex.filter(el=> el.ind === index).length > 0 ? true : false}
                                        onChange={(e)=> selectedScenarioChangeHandler(e.target.checked,index) }
                                        />
                                        <label htmlFor={`setLabel${index}`}>{elem.name}</label>
                                    </div>
                                    <div className={Style.setScenarioRight}>
                                        <Select
                                        rootClassName={Style.setJobSelectCnt}
                                        disabled={selectedScenariosIndex.filter(el=> el.ind === index).length === 0 ? true : false}
                                        mode="multiple"
                                        value={ selectedScenariosIndex.filter(el=> el.ind === index).length > 0 ? 
                                        selectedScenariosIndex.filter(el=> el.ind === index)[0].jobs.map(el=> `${el}`) : 
                                        []
                                        }
                                        maxTagCount={1}
                                        placeholder="Include Jobs"
                                        options={elem.jobs.map((el,ind)=> ({label:el.name,value:`${ind}`}) ) } 
                                        onChange={(e)=> jobChangeHandler(index,e) }
                                        />
                                    </div>
                                </div>
                                ))
                            }
                           
                           </div>

                           <div className="mpuBot">
       
                           <Button className="mybtn-blue" type="text" onClick={selectedScenarioSaveHandler}>
                               Save Settings <RightOutlined />
                           </Button>
       
                           </div>
       
                       </div>        
               </Modal>
            }
            {
                result.length > 0 &&
                <OutputScreen 
                result={result}
                setResult={setResult}
                myCurrency={myCurrency}
                submitableScenario={submitableScenario}
                />

            }
            {
                result.length < 1 &&
                <>
                <div className={Style.container}>
                    <div className={Style.left}>
                        <div className={Style.leftInner}>
                            <h1>Quantum Leap<sup>TM</sup></h1>
                            <img src="/images/trio.png" alt="background logo" />
                            <h4>Sales & Product Information Input Form</h4>
                            <p className={Style.indexMob} style={{margin:"20px 0 0 0"}}>
                                Step <span style={{color:"#24336D"}}>{activeIndex+1}</span> of <span style={{color:"#24336D"}}>{calcList?.length}</span>
                            </p>

                        <div className={Style.timelineCnt} style={{margin:"40px 0 0 0"}}>

                                <TimelineContainer 
                                calcList={calcList![0]}
                                />
                        </div>
                        
                        </div>
                    </div>
                    <div className={Style.right}>

                    <div className={Style.rightTopCnt}>
                        <div className={Style.rightTopUpper}>
                            <div className={Style.rightTop}>
                            <Button type="text" className={Style.rtBtn} onClick={()=> setConfirmRestart(true)}>
                                <AutorenewIcon fontSize="inherit" sx={{margin:"0 5px 0 0"}} />
                                Restart Form
                            </Button>
                            <Select 
                                options={[{value:"dollar",label:"$ Dollar"},{value:"rupee",label:"₹ Rupee"}]}
                                className={`${Style.currencyDrop}`}
                                onChange={(e)=> { dispatch( setCurrency(e) ) } } 
                                value={myCurrency}
                                defaultValue={myCurrency} 
                                size="large"
                                bordered={false}
                                />
                            </div>
                            
                            <Clock />
                        
                        </div>
                    </div>
                        
                        <div className={Style.innerRight}>

                        <div className={Style.calcCnt}>

                            <div className={Style.calcHeader}>
                                <div>
                                    <div className={Style.bullet} />
                                    <p>{calcList![0][activeIndex].name}</p>

                                </div>
                            </div>

                            <div className={Style.calcMain}>
                                <div className={Style.calcContent}>
                                    
                                    {

                                    localData.map((outerElem,outerIndex)=>(
                                        
                                        <div className={Style.calcDivisionCnt} key={`ou${activeTab}${activeScenarioIndex}${activeJob}${outerIndex}${activeIndex}`}>
                                            {
                                                (splitData && splitData.length >= 2 && localData.length > 1) &&
                                                <div className={Style.dataHeaderCnt}>
                                                    <p className={Style.dataHeader}>
                                                    {
                                                        activeTabJobs[parseInt(splitData[outerIndex])].name
                                                    }
                                                    </p>
                                                    <NavigationIcon
                                                    sx={{
                                                        transform:"rotate(180deg)",
                                                        height:"17px",
                                                        color:"lightgray"
                                                    }}
                                                    />
                                                </div>
                                            }
                                            {
                                                outerElem.map((elem,index)=>(
                                                    <div key={`ouin${activeTab}${activeScenarioIndex}${activeJob}${outerIndex}${activeIndex}${index}`}>
                                                {
                                                    (elem.type === "DoublePopup") &&
                                                    <DoublePopup
                                                    calcList={calcList}
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "popup" &&
                                                    <Popup
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "nestedNum" &&
                                                    <Number
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
            
                                                {
                                                    elem.type === "text" &&
                                                    <Text
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "num" &&
                                                    <Number
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "bool" &&
                                                    <BoolDrop
                                                    calcList={calcList}
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                {
                                                    elem.type === "decisive" &&
                                                    <Decisive
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                 {
                                                    elem.type === "oneScreenPopup" &&
                                                    <OneScreenPopup
                                                    activeIndex={activeIndex}
                                                    outerIndex={outerIndex}
                                                    index={index}
                                                    data={elem}
                                                    currency={myCurrency}
                                                    />
                                                }
                                                </div>
                                                ))
                                            }
                                        </div>
                                    ))
                                
                                    }        

                                </div>

                                <div className={Style.calcBtnCnt}>
                                    <Button 
                                    type="text" 
                                    className={Style.calcBtn} 
                                    disabled={activeIndex === 0 ? true : false}
                                    onClick={()=> dispatch( verifyFormPage(activeIndex - 1) ) }
                                    >
                                        {activeIndex > 0 && <LeftOutlined style={{fontSize:"10px"}} /> } Back
                                    </Button>
                                    
                                    <Button 
                                    loading={loading}
                                    type="text" 
                                    className={Style.calcBtn} 
                                    onClick={()=> verifyForm() }
                                    disabled={activeIndex === calcList![0].length-1 ? true : false}
                                    >
                                    Next {activeIndex < calcList![0].length-1 && <RightOutlined style={{fontSize:"10px"}} /> }
                                    </Button>
                                        
                                    
                                </div>

                            </div>

                        </div>

                        </div>

                    </div>

                </div>

                <div className="calculateBtnCnt">
                    <Button loading={loading} className={`calculateBtn ${selectedScenariosIndex.length > 0 && "calculateBtnActive"}`} type="text" onClick={calculate}>calculate</Button>
                    <SettingsIcon 
                    className={`calculateSettingBtn ${selectedScenariosIndex.length > 0 && "calculateSettingBtnActive"}`}
                    onClick={()=> setSettingOpen(true)}
                    />
                </div>
                
                </>
            }
        </div>
    )

}

export default memo(Calculator);