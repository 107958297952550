import { memo, useEffect, useRef, useState } from "react";
import { CalculatorChildType, newFormInpPropType } from "../../@types/user";
import question_ico from "../../svg/question.svg";
import InputBox from "../input";
import { Alert, Button, Input } from "antd";
import { CloseOutlined, RightOutlined } from "@ant-design/icons";
import { Modal } from "@mui/material";
import { CircleNotifications } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { boolClose, boolInnerInputChange, boolInputchange, boolOk } from "../../redux/calculatorSlice";
import ModalBox from "../modal";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

const BoolDrop = ({calcList,activeIndex,index,data,currency,outerIndex}:newFormInpPropType)=>{

    const version = useSelector((state:RootState)=> (state.calculator.tabs[state.calculator.activeTab].version) );

    const [showMsg,setShowMsg] = useState<boolean>(false);
    const msgCnt = useRef<any>();
    const msgRef = useRef<any>();

    const [targetElem,setTargetElem] = useState(2);
    const [targetData,setTargetData] = useState<CalculatorChildType>();
    const [lastInvValue,setLastInvValue] = useState<number>(0);
    const [showPopup2,setPopUp2] = useState(false);
    const [supSub,setSupSub] = useState<{name:string,value:string,type:string,currency?:boolean}[][]>([]);
    const [innerError,setInnerError] = useState(false);

    const dispatch = useDispatch();


    useEffect(()=>{

        window.addEventListener('click',windowClickFunction);


        return ()=>{
            window.removeEventListener('click',windowClickFunction)
        }

      },[]);

      useEffect(()=>{
        if(!calcList || !targetElem) return;

        let prep = [...calcList!];
        let prep2 = prep[outerIndex];

        if(!prep2) return;

        let ggi = [...prep2];
        let gg = ggi[activeIndex].child[targetElem];

        if(!gg) return;
        
        if(gg){
            setTargetData(gg);
        }
        

        if(gg.entries)
        setLastInvValue(gg.entries.length)        

        let x = gg?.entries || [];
        if(x.length > 0){
            setSupSub(x);
        }
      },[calcList,targetElem])

      const windowClickFunction = (e:any)=>{
  
        if(msgCnt.current && msgRef.current){

            if (!msgCnt.current.contains(e.target) && !msgRef.current.contains(e.target)){
                setShowMsg(false);
            }

        }
  
      }

      const changefunc = async(e:any)=>{
       
        let ret = await dispatch( boolInputchange({
            value:e.target.value,
            outerIndex : outerIndex,
            innerIndex: index,
            lastInpValue : lastInvValue
        }))

        let retOb = JSON.parse(ret.payload.value);

        // setTargetData(retOb.targetElem);
        // setLastInvValue(retOb.lastInpValue);
        setPopUp2(retOb.popup2 || false);
        // setSupSub([...retOb.supSub]);

      }


      const closeFunc = (e:any)=>{
    

        dispatch( boolClose({
            value:"",
            outerIndex: outerIndex,
            innerIndex: index
        }))

       
        setPopUp2(false);
      }



    return(
        <>

        <Modal
            className="modal"
            open={showPopup2}
            onClose={closeFunc}
            >
                <div className="modalPopUpGrid">

                <CloseOutlined
                className="popupClose" 
                onClick={closeFunc}
                />
                    <div className="mpugTop">

             
                        {
                            targetData?.entries?.map((elem,ind)=>(
                                <div key={`$nestedBool${ind}`}>
                                    <h4 style={{margin:"10px 0",color:"#24336D"}}>
                                        {
                                           `${targetData?.popupArrayName} ${ind+1}`
                                        }
                                        </h4>
                                    {
                                        elem!!.map((ielem,iind)=>(
                                            <InputBox
                                            key={`nestedBool${ind}${iind}`}
                                            type={ielem.type}
                                            placeholder={ielem.name}
                                            value={ielem.value}
                                            image={<CircleNotifications />}
                                            currency={ielem.currency}
                                            onChange={(e:any)=>{

                                                dispatch( boolInnerInputChange({
                                                    value:JSON.stringify({
                                                        targetElem : targetElem,
                                                        data : e.target.value,
                                                        ind,
                                                        iind
                                                    }),
                                                    outerIndex : outerIndex,
                                                    innerIndex : index

                                                }));
                                                
                                            
                                            }}
                                            />
                                        ))
                                    }
                                </div>
                            ))
                        }                    
              

                    </div>

                    <div className="mpugBot">

                    <Button className="mybtn-blue" type="text" onClick={()=>{

                            const res:{csp:number,cbc:number}={
                                csp:0,
                                cbc:0,
                            }

                            let err = false;

                            for(var i=0; i<supSub.length; i++){

                                for(var j=0; j<supSub[i].length; j++){
                                    if(!supSub[i][j].value){
                                        err = true;
                                    }
                                    if(j===0){
                                        res.csp += parseFloat(supSub[i][j].value) || 0;
                                    }
                                    if(j===1){
                                        res.cbc += parseFloat(supSub[i][j].value) || 0;
                                    }
                                }
                            }

                            if(res.cbc <1 || res.csp < 1){
                                err = true;
                            }

                            if(!err){
                                setPopUp2(false);
                                dispatch(boolOk({
                                    value: JSON.stringify({res,supLength:supSub.length,error:err}),
                                    outerIndex:outerIndex,
                                    innerIndex : index
                                }))
                            }
                            else{

                                setInnerError(true);

                            }
                         
                       
                    }}>
                        Done <RightOutlined />
                    </Button>

                    </div>

                </div>           

            </Modal>

            {
                innerError &&
                <ModalBox 
                open={innerError}
                onClose={()=> setInnerError(false)}
                onOk={()=> setInnerError(false)}
                header={`Improper Entries`}
                message={`You have one or more empty fileds. Enter 0 if there is no value !`}
                />
            }
            
            <div className="formMainCnt">
                <p className="formTitle">{data?.name}</p>
                <div className={`formInputCnt ${data?.error && "error"}`}>

                <select 
                    className="mainInput myselect" 
                    value={data?.value} 
                    disabled={data?.disabled}
                    onChange={changefunc} 
                    >
                        <option value="yes">Yes</option>
                        <option value="no">No</option>
                    </select>

                <img className="formQuestion" src={question_ico} alt="show input field description button"
                onClick={()=> setShowMsg(true)}
                ref={msgRef}
                 />
                </div>

                {
                    data?.error &&
                    <Alert 
                    style={{fontSize:"12px",padding:"5px 10px"}} 
                    description="Mandatory Field" 
                    type="error" 
                    showIcon={false} />
                }
                {
                    showMsg &&
                    <div className="formMsg" ref={msgCnt}>
                        {
                        version === "v3" ?
                        (data && data.msg) &&
                            data.msg.map((elem,index)=>(
                                <p key={`msg${index}`}>{elem}</p>
                            )) 
                        :
                        <p>{data?.msg}</p>
                        
                        
                       }
                    </div>
                }
            </div>
        </>
    )

}

export default memo(BoolDrop);